<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel" v-if="selected.length > 0">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        fixed-header
        v-model="selected"
        show-select
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.totalAmount="{ item }">
          {{ formatPrice(item.totalAmount) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "tanda-terima-kasbon",
  data() {
    return {
      params: {
        startDate: moment().format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
      },
      menuStartDate: false,
      menuEndDate: false,
      items: [],
      selected: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Kasbon#",
          sortable: false,
          value: "kasbonNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "DATE",
          sortable: false,
          value: "kasbonDate",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Employee",
          sortable: false,
          value: "name",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Remark",
          sortable: false,
          value: "remark",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Payment Type",
          sortable: false,
          value: "payment",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Amount",
          sortable: false,
          value: "totalAmount",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Tanggal Penyerahan",
          sortable: false,
          value: "kasbonDate",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/tandaTerimaKasbonExcel", this.selected)
        .then(response => {
          fileDownload(response.data, `tanda-terima-kasbon.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/tandaTerimaKasbon", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
